import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Col, Form, Button } from "react-bootstrap"
import Swal from "sweetalert2"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      emails: file(relativePath: { eq: "emails.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [email, setemail] = useState("")

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    switch (name) {
      case "email":
        setemail(value)
        break
      default:
        break
    }
  }

  const resetForm = () => {
    setemail("")
  }

  const handleSubmit = event => {
    event.preventDefault()
    // setisLoadeding(true);

    fetch(
      "https://mu6dbe9y4f.execute-api.us-east-1.amazonaws.com/default/OptoutFormTWFI",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email.trim(),
        }),
      }
    )
      .then(res => res.json())
      .then(
        result => {
          // setisLoadeding(false);
          // Swal.fire("Message Sent!", "Thank you, we will get back you you as soon as possible")
          Swal.fire({
            icon: "success",
            title: "You have successfully unsubscribed.",
            showConfirmButton: false,
            timer: 3000,
          })
          resetForm()
        },
        error => {
          // setisLoadeding(false);
          Swal.fire(
            "Unexpected Error!",
            "Sorry, Something went wrong. Please try again later.",
            "error"
          )
          resetForm()
        }
      )
  }
  return (
    <Layout style={{ backgroundColor: "white" }}>
      <SEO title="Unsubscribe" />
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <h1>Unsubscribe?</h1>
        <p>You will no longer recieve news or promotions.</p>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }}
                  required
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleChange}
                  size="sm"
                  maxLength={50}
                />
              </Form.Group>
              <Button
                style={{ width: "100%", maxWidth: "500px" }}
                variant="primary"
                type="submit"
                size="sm"
              >
                Unsubscribe
              </Button>
            </Col>
          </Form.Row>
        </Form>
        <br />
        <Link className="navLink" to="/">
          Nevermind, take me home..
        </Link>
      </div>
      <Img
        style={{ maxWidth: "500px", margin: "10vh auto" }}
        fluid={data.emails.childImageSharp.fluid}
      />
    </Layout>
  )
}

export default NotFoundPage
